import React from "react"
import ReactDOM from "react-dom/client"
import ContactForm from "./ContactForm"
import PaymentForm from "./PaymentForm"
import ReglementsScrollArea from "./ReglementsScrollArea"

const contactFormContainer = document.getElementById("contact_form_container")
if (contactFormContainer) {
  ReactDOM.createRoot(contactFormContainer).render(
    <React.StrictMode>
      <ContactForm />
    </React.StrictMode>
  )
}

const paymentFormContainer = document.getElementById("payment_form_container")
if (paymentFormContainer) {
  ReactDOM.createRoot(paymentFormContainer).render(
    <React.StrictMode>
      <PaymentForm />
    </React.StrictMode>
  )
}

const reglementsScrollAreaContainer = document.getElementById("reglements_scroll_area")
if (reglementsScrollAreaContainer) {
  ReactDOM.createRoot(reglementsScrollAreaContainer).render(
    <React.StrictMode>
      <ReglementsScrollArea />
    </React.StrictMode>
  )
}

import { useState } from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"

const PaymentSchema = Yup.object().shape({
  ref: Yup.string().min(2).required(),
  prenom: Yup.string().min(2).required(),
  nom: Yup.string().min(2).required(),
  telephone: Yup.string().required(),
  email: Yup.string().email().required(),
  adresse: Yup.string().required().min(5).required(),
  adresse2: Yup.string(),
  cp: Yup.string().required().min(5).max(5),
  ville: Yup.string().min(2).required(),
  pays: Yup.string().min(2).required(),
  montant: Yup.number().required(),
})

const PaymentForm = () => {
  const [sent, setSent] = useState(false)

  return (
    <div className="flex flex-col justify-between w-full">
      <div className="grow-0 shrink-0">
        <h1 className="text-5xl font-bold tracking-tight">
          Je règle mon dossier
          <br />
          <span className="font-serif italic font-semibold">maintenant</span>
        </h1>
      </div>
      <div className={`${sent ? "hidden" : ""} grow shrink mt-20`}>
        <Formik
          initialValues={{
            ref: "",
            prenom: "",
            nom: "",
            telephone: "",
            email: "",
            adresse: "",
            adresse2: "",
            cp: "",
            ville: "",
            pays: "France",
            montant: "",
          }}
          validationSchema={PaymentSchema}
          onSubmit={async (values) => {
            try {
              alert(JSON.stringify(values, null, 2))
              setSent(true)
              // const response = await fetch("/wp-content/themes/dpv-huissiers/api/sendMessage.php", {
              //   method: "POST",
              //   headers: {
              //     "Content-Type": "application/json",
              //   },
              //   body: JSON.stringify(values),
              // })
              // const text = await response.text()
              // console.log(text)
              // if (response.ok) {
              //   setSent(true)
              // } else {
              //   setSent(false)
              //   console.error("Une erreur est survenue lors de l'envoi du message.")
              // }
            } catch (error) {
              setSent(false)
              console.error("Une erreur est survenue : ", error)
            }
          }}>
          {(props) => {
            const { touched, errors, isSubmitting, handleSubmit } = props
            return (
              <form className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6" onSubmit={handleSubmit}>
                <div className="space-y-6 col-span-2 md:col-span-1">
                  <Field
                    id="ref"
                    name="ref"
                    placeholder="Référence dossier*"
                    disabled={sent}
                    className={`${errors.ref && touched.ref ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="prenom"
                    name="prenom"
                    placeholder="Prénom*"
                    disabled={sent}
                    className={`${errors.prenom && touched.prenom ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="nom"
                    name="nom"
                    placeholder="Nom*"
                    disabled={sent}
                    className={`${errors.nom && touched.nom ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="telephone"
                    name="telephone"
                    placeholder="Téléphone*"
                    disabled={sent}
                    className={`${errors.telephone && touched.telephone ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="email"
                    name="email"
                    placeholder="E-mail*"
                    disabled={sent}
                    className={`${errors.email && touched.email ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                </div>
                <div className="space-y-6 col-span-2 md:col-span-1">
                  <Field
                    id="adresse"
                    name="adresse"
                    placeholder="Adresse*"
                    disabled={sent}
                    className={`${errors.adresse && touched.adresse ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="adresse2"
                    name="adresse2"
                    placeholder="Complément d'adresse"
                    disabled={sent}
                    className={`${errors.adresse2 && touched.adresse2 ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="cp"
                    name="cp"
                    placeholder="Code postal"
                    disabled={sent}
                    className={`${errors.cp && touched.cp ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="ville"
                    name="ville"
                    placeholder="Ville*"
                    disabled={sent}
                    className={`${errors.ville && touched.ville ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                  <Field
                    id="pays"
                    name="pays"
                    placeholder="Pays*"
                    disabled={sent}
                    className={`${errors.pays && touched.pays ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                  />
                </div>
                <Field
                  id="montant"
                  name="montant"
                  type="number"
                  step=".01"
                  lang="fr"
                  placeholder="Montant à régler (en €)"
                  disabled={sent}
                  className={`${errors.nom && touched.nom ? "border-red-500" : "border-stone-400"} ${
                    sent ? "bg-gray-200" : ""
                  } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50 col-span-2 font-bold`}
                />
                <div className="col-span-2">
                  {!sent && (
                    <div className="flex items-center justify-between gap-6">
                      <div className="text-xs italic text-right">
                        En cliquant sur envoyer, vos données seront envoyées vers le système bancaire. Le paiement ne sera finalisé qu'une fois vos coordonnées bancaires saisies et validées.
                      </div>
                      <button
                        type="submit"
                        className={`${
                          !props.isValid || isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:bg-[#BFE2FF]/50 hover:border-[#BFE2FF]/50 cursor-pointer"
                        } inline-flex items-center justify-center font-bold border rounded-full border-[#BFE2FF] px-10 py-4 bg-[#BFE2FF]`}
                        disabled={isSubmitting}>
                        {isSubmitting && (
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        )}
                        Envoyer
                      </button>
                    </div>
                  )}
                  {sent && (
                    <div className="flex items-center justify-start gap-3 text-green-600">
                      <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                          <path d="m4 8l2.05 1.64a.48.48 0 0 0 .4.1a.5.5 0 0 0 .34-.24L10 4" />
                          <circle cx="7" cy="7" r="6.5" />
                        </g>
                      </svg>
                      <p>Votre paiement continue dans un nouvel onglet !</p>
                    </div>
                  )}
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
      <div className={`${sent ? "" : "hidden"} grow shrink mt-20`}>
        <span onClick={() => setSent(false)}>Modiifer la saisie</span>
      </div>
    </div>
  )
}

export default PaymentForm
